import { Center, Spinner } from '@hausgold/designsystem';
import { useTranslation } from 'react-i18next';
import Page from 'app-components/Page';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { PAGE_CONTENT_PADDING } from 'app-utils/constants/ui';

/**
 * Index of the Maklerportal
 * This page redirects to the actual 'homepage' of the MPN.
 */
const IndexPage = () => {
  const { t } = useTranslation();
  const router = useRouter();

  // Redirect to current homepage
  useEffect(() => {
    router.push('/dashboard');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Render component
   */
  return (
    <Page title={t('index.title')} layout="sidebar">
      <Center py={PAGE_CONTENT_PADDING}>
        <Spinner data-testid="indexSpinner" />
      </Center>
    </Page>
  );
};

export default IndexPage;
